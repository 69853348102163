import React from "react";
import MissionAndVision from "./MissionAbout/MissionAbout";
import { WhoWeAre } from "./WhoWeAre/WhoWeAre";
import aboutUs from "../../assets/about-banner.png";

export const AboutUs = () => {
  return (
    <div>
      <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
        <div className="absolute md:mt-24">
          <div className="lg:w-full md:w-11/12 w-2/4 text-center max-h-fit">
            <p className="text-justify text-transparent bg-clip-text bg-gradient-to-r from-[#53BCAC] to-[#F0F0F0] pl-4 lg:pl-16 md:pl-8 text-md font-semibold text-[#3AB3AD] lg:text-8xl md:text-7xl text-2xl">
              About Us
            </p>
            <p className="text-justify pl-4 lg:pl-16 md:pl-8 md:font-semibold text-md font-sans font-light text-[#484848] lg:text-2xl md:text-xl text-sm">
              {" "}
              Innovative Solutions for a Digital World
            </p>
            <p className="mt-2 lg:mt-8 md:mt-5 text-justify w-full md:w-7/12 lg:w-6/12 pl-4 lg:pl-16 md:pl-8 font-sans font-normal text-[#484848] lg:text-md md:text-lg text-xs">
              {" "}
              Pando India Software Consultants have been an innovator in delivering innovative
              solutions since 2017. With a focus on leveraging advanced technologies, we craft
              tailored strategies that empower businesses to adapt, grow, and succeed in a
              constantly evolving landscape. Our commitment to excellence and client satisfaction
              drives everything we do, ensuring impactful and efficient outcomes for our partners.
            </p>
          </div>
        </div>
        <div className="lg:w-full">
          <img className="object-cover lg:w-[100%] h-auto" src={aboutUs} />
        </div>
      </div>
      <WhoWeAre />
      <MissionAndVision />
    </div>
  );
};
