import React from "react";
import banner from "../../assets/big-data.png";
import BigDataIll from "../../assets/big-data-ill.svg";

const BigData = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Big Data
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-start leading-relaxed whitespace-pre-wrap">
                    A software service in big data refers to a cloud-based or web-based application
                    that provides functionalities and capabilities related to processing and
                    analyzing large and complex datasets. These services are designed to handle the
                    challenges of big data, such as volume, velocity, and variety, and enable
                    organizations to extract valuable insights and knowledge from their data.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>Data Processing Services: </b>These services provide tools and frameworks for
                    processing large-scale data, such as batch processing, real-time stream
                    processing, and interactive querying. They may offer distributed processing
                    capabilities, such as Apache Hadoop, Apache Spark, or Apache Flink, that allow
                    organizations to process and analyze data in parallel across multiple nodes or
                    clusters.
                  </p>
                  <p className="mt-4">
                    <b>Data Storage and Management Services: </b>These services offer scalable and
                    distributed storage solutions for big data, such as data lakes, data warehouses,
                    and NoSQL databases. They may provide features for data ingestion, data
                    integration, data transformation, and data governance, such as Amazon S3, Google
                    BigQuery, Microsoft Azure Data Lake Storage, or MongoDB.
                  </p>
                  <p className="mt-4">
                    <b>Data Analytics and Visualization Services: </b>These services provide tools
                    and capabilities for performing advanced analytics, machine learning, and data
                    visualization on big data. They may offer pre-built analytics algorithms,
                    libraries, and visualization tools, as well as features for data exploration,
                    data visualization, and data insights, such as Apache Zeppelin, Tableau, or
                    Power BI.
                  </p>
                  <p className="mt-4">
                    <b>Data Integration and ETL (Extract, Transform, Load) Services: </b>These
                    services provide tools and features for integrating data from different sources,
                    transforming data into desired formats, and loading data into target systems.
                    They may offer data pipelines, data connectors, and data transformation
                    capabilities, such as Apache NiFi, Google Dataflow, or Microsoft Azure Data
                    Factory.
                  </p>
                  <p className="mt-4">
                    <b>Data Security and Governance Services: </b>
                    These services provide features and capabilities for ensuring the security,
                    privacy, and compliance of big data. They may offer data encryption, access
                    controls, auditing, and monitoring features, as well as tools for data masking,
                    data classification, and data lineage, such as Apache Ranger, Google Cloud Data
                    Loss Prevention, or AWS Glue Data Catalog.
                  </p>
                  <p className="mt-4 mb-10">
                    Examples of popular software services in big data include Amazon Web Services
                    (AWS) Big Data services, Google Cloud Big Data services, Microsoft Azure Big
                    Data services, Apache Bigtop, and Cloudera Data Platform. These services provide
                    a wide range of capabilities for processing, storing, analyzing, and managing
                    big data, and are widely used by organizations to handle their large-scale data
                    requirements and extract insights from their data.
                  </p>
                </div>
              </div>
              <img src={BigDataIll} alt="bigData-img" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigData;
