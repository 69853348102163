export function ServiceText() {
  return (
    <div>
      <div className="text-center ">
        <p
          className="text-[3.5rem] md:text-[9rem] lg:text-[9rem] sm:text-[9rem] md:opacity-5"
          id="serviesText"
        >
          Solutions
        </p>
        <p className="text-lg md:text-2xl text-gray-500 relative bottom-[58px] md:bottom-[120px] sm:bottom-[120px] lg:bottom-[120px]">
          Our Service/Insights
        </p>
      </div>
    </div>
  );
}
