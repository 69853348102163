import React from "react";
import banner from "../../assets/internet-things.png";
import internetThings from "../../assets/internet-things.svg";

const InternetThings = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Internet of Things
          <br /> (IoT)
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-start leading-relaxed whitespace-pre-wrap">
                    Internet of Things (IoT) is a rapidly growing field that involves connecting
                    everyday objects and devices to the internet to enable communication, data
                    collection, and automation. Software services play a critical role in
                    developing, deploying, and managing IoT solutions. Here are some common software
                    services in the realm of IoT:
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>IoT Platform: </b>An IoT platform is a software framework that provides the
                    infrastructure and tools for developing, deploying, and managing IoT
                    applications. It typically includes features such as device management, data
                    ingestion, data processing, analytics, and visualization. Examples of popular
                    IoT platforms include Microsoft Azure IoT, AWS IoT, and Google Cloud IoT.
                  </p>
                  <p className="mt-4">
                    <b>Device Management: </b>Device management software services are used to
                    provision, configure, monitor, and update IoT devices remotely. These services
                    enable efficient management of large numbers of devices across different
                    locations and provide features such as firmware updates, security patches, and
                    remote troubleshooting.
                  </p>
                  <p className="mt-4">
                    <b>Data Analytics: </b>IoT generates massive amounts of data from connected
                    devices, sensors, and other sources. Data analytics software services enable
                    processing, analyzing, and deriving insights from this data. This includes
                    real-time data processing, data visualization, predictive analytics, and machine
                    learning algorithms to identify patterns, trends, and anomalies in the data.
                  </p>
                  <p className="mt-4">
                    <b>Security and Identity Management: </b>Security is a critical concern in IoT,
                    as connected devices and data are vulnerable to cyber threats. Software services
                    for IoT security provide features such as authentication, authorization,
                    encryption, and security monitoring to ensure the confidentiality, integrity,
                    and availability of IoT data and devices.
                  </p>
                  <p className="mt-4">
                    <b>Connectivity and Communication: </b>IoT devices need to communicate with each
                    other and with cloud-based services. Software services for connectivity and
                    communication enable protocols such as MQTT, CoAP, and HTTP to facilitate
                    communication between devices and with the cloud. These services also handle
                    device discovery, addressing, and routing issues.
                  </p>
                  <p className="mt-4">
                    <b>Application Development: </b>IoT applications often require custom software
                    development to meet specific requirements. Application development software
                    services provide tools, frameworks, and libraries for building IoT applications,
                    including user interfaces, business logic, and integrations with other systems.
                  </p>
                  <p className="mt-4">
                    <b>Edge Computing: </b>Edge computing is a computing paradigm that brings
                    processing and analytics closer to the source of data, typically at the edge of
                    the network where IoT devices are deployed. Edge computing software services
                    enable running applications, processing data, and making decisions at the edge,
                    reducing the need for sending all data to the cloud for processing.
                  </p>
                  <p className="mt-10">
                    Cloud Services: Cloud-based services are often used in IoT deployments for
                    storing, processing, and analyzing data. Cloud services provide scalable
                    computing resources, data storage, and analytics capabilities that can be
                    accessed by IoT applications anywhere, anytime.
                  </p>
                  <p className="mt-4 mb-10">
                    These are just some examples of the software services that we are providing now
                    for IoT deployments.
                  </p>
                </div>
              </div>
              <img src={internetThings} alt="internet-img" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternetThings;
