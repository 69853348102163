import React from "react";
import { BlogDes } from "../../components/BlogDes/BlogDes";
import { Card } from "../../components/Card/Card";
import { DigitallyEmpowering } from "../../components/DigitallyEmpowering";
import { EmpoweringBrands } from "../../components/EmpoweringBrands";
import { Launching } from "../../components/Launching/Launching";

import { HeroSession } from "../../components/Hero/Hero";
import { PandoUniqueText } from "../../components/PandoUniqueText/PandoUniqueText";
import { ServiceText } from "../../components/Service_text/Service_text";
import { Step } from "../../components/Step";

export const Home = () => {
  return (
    <div>
      <HeroSession />
      <Launching />
      {/* <EmpoweringBrands /> */}
      <ServiceText />
      <Card />
      <Step />
      {/* <DigitallyEmpowering /> */}
      <PandoUniqueText />
      <BlogDes />
    </div>
  );
};
