import React, { useState } from "react";
import contactX from "../../assets/contactX.png";
import { ContactUsCard } from "./ContactUsCard";
import contactUs from "../../assets/Contact-us.svg";
// import { contactApi } from "../../api/api";
import axios from "axios";

export const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [suggestion_area, setSuggestion_area] = useState("");
  const [suggestion_query, setSuggestion_query] = useState("");

  const handleChange = async () => {
    try {
      if (!name || !email || !mobile || !suggestion_area || !suggestion_query) {
        alert("Please fill the mandatory fields");
        return;
      }

      const nameRegex = /^[A-Za-z\s]+$/;
      if (!nameRegex.test(name) || name.trim().length === 0) {
        alert(
          "Please enter a valid name (only letters and spaces allowed, and it must have at least one character)."
        );
        return;
      }

      const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        alert("Please enter a valid email");
        return;
      }

      const mobileRegex = /^\+(\d{1,3})\d{10}$/;
      if (!mobileRegex.test(mobile)) {
        alert(
          "Please enter a valid mobile number with country code (e.g., +1 followed by 10 digits)."
        );
        return;
      }

      if (suggestion_area === "0") {
        alert("Please select option");
        return;
      }

      const descriptionRegex = /^[a-zA-Z.,\s]{0,200}$/;
      if (!descriptionRegex.test(suggestion_query)) {
        alert(
          "Description can only contain alphabets, full stops, commas, and spaces (max 200 characters)."
        );
        return;
      }

      const { data } = await axios.post("http://localhost:5000/api/contactus", {
        name,
        email,
        mobile,
        suggestion_area,
        suggestion_query,
      });

      if (data.success) {
        alert("Form submit successfully");
        setName("");
        setEmail("");
        setMobile("");
        setSuggestion_area("");
        setSuggestion_query("");
        return;
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };

  return (
    <div className="w-full">
      <div className="font-sans">
        <div className="flex flex-col-reverse items-center w-full mx-auto md:flex-row gap-20">
          <div className="lg:w-full md:w-full w-full md:pl-8 lg:pl-32 p-4">
            <p className=" text-6xl text-transparent bg-clip-text bg-gradient-to-r from-[#53BCAC] to-[#F0F0F0] font-bold leading-normal">
              Contact Us
            </p>
            <p className="w-full leading-7 text-justify text-[#484848]">
              Pando India Software Consultants is USA based on Market research and Finance Software
              services with a brilliant team and technology.
            </p>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Name<span className="text-red-500 text-xl"> *</span>
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your Name"
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                />
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Email Address<span className="text-red-500 text-xl"> *</span>
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your Email Address"
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                />
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Mobile Number<span className="text-red-500 text-xl"> *</span>
                </label>
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Enter your Mobile Number"
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                />
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Suggestions/Queries Areas<span className="text-red-500 text-xl"> *</span>
                </label>
                <select
                  value={suggestion_area}
                  onChange={(e) => setSuggestion_area(e.target.value)}
                  className="border border-grey-400 text-black focus:outline-none lg:w-9/12 md:w-full w-full px-3 py-2 text-sm text-start rounded-md"
                >
                  <option value="0">Select your Suggestions/Queries</option>
                  <option value="1">Product</option>
                  <option value="2">Services</option>
                  <option value="3">Collaboration</option>
                  <option value="4">Support</option>
                  <option value="5">Media</option>
                  <option value="6">Advertisement</option>
                  <option value="7">Others</option>
                </select>
                {/* 1-Product, 2-Services, 3-Collaboration, 4-Support, 5-Media, 6-Advertisement, 7-Others */}
              </div>
            </div>
            <div className="w-full my-5 item-center">
              <div>
                <label className="block mb-2 text-black">
                  Your Suggestions/Queries<span className="text-red-500 text-xl"> *</span>
                </label>
                <textarea
                  value={suggestion_query}
                  onChange={(e) => setSuggestion_query(e.target.value)}
                  maxLength={200}
                  className="block border-grey-400 text-black focus:outline-none p-2.5 lg:w-9/12 md:w-full w-full border text-sm rounded-lg "
                  placeholder="Project description"
                ></textarea>
                <div className="text-xs mt-1 text-end lg:w-9/12 md:w-full w-full">{`${
                  200 - suggestion_query.length
                } characters remaining`}</div>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full my-2 ">
                <div className="">
                  <button
                    type="submit"
                    className="bg-orange-500 h-10 text-lg text-black mb-5 lg:w-9/12 md:w-full w-full rounded-md font-medium py-1 text-white"
                    onClick={handleChange}
                  >
                    Submit
                  </button>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="relative w-full lg:w-full md:w-full flex">
              <img src={contactUs} alt="" className="maw-full ml-auto" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-8">
        <ContactUsCard />
      </div>
    </div>
  );
};
