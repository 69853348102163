import React from "react";
import banner from "../../assets/ios-banner.png";
import ios from "../../assets/ios-img.svg";

const Ios = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          iOS Development
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-start leading-relaxed whitespace-pre-wrap">
                    iOS development services refer to the process of designing, developing, and
                    testing mobile applications specifically for Apple&apos;s iOS operating system,
                    which powers devices such as iPhones, iPads, and iPod Touches. iOS development
                    services typically involve a combination of coding, design, and testing to
                    create high-quality, functional, and user-friendly iOS applications.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>App Development: </b>This involves creating custom iOS applications from
                    scratch, tailored to the specific requirements of the client. This includes
                    designing the user interface (UI), developing the app&apos;s functionality using
                    programming languages such as Swift or Objective-C, integrating with databases
                    or APIs, and implementing any required features such as push notifications,
                    in-app purchases, and social media integration.
                  </p>
                  <p className="mt-4">
                    <b>App Design: </b>App design focuses on creating visually appealing and
                    intuitive user interfaces (UI) for iOS applications. This includes designing the
                    layout, color schemes, typography, icons, and graphics to create a visually
                    appealing and user-friendly interface that aligns with the brand and target
                    audience.
                  </p>
                  <p className="mt-4">
                    <b>App Testing: </b>App testing involves thoroughly testing the iOS application
                    to identify and fix any bugs or issues before it is released to the App Store.
                    This includes functional testing, usability testing, performance testing, and
                    compatibility testing on different iOS devices and versions.
                  </p>
                  <p className="mt-4">
                    <b>App Maintenance and Support: </b>
                    Once the iOS app is developed and released, ongoing maintenance and support may
                    be required to ensure its smooth operation. This includes monitoring, updates,
                    bug fixes, and addressing any technical issues that may arise after the app is
                    launched.
                  </p>
                  <p className="mt-4">
                    <b>App Optimization: </b>
                    App optimization involves analyzing the performance and usage of the iOS app and
                    making optimizations to improve its performance, responsiveness, and user
                    experience. This may include optimizing code, improving app loading times,
                    optimizing database queries, and enhancing overall app performance.
                  </p>
                  <p className="mt-4">
                    <b>App Migration: </b>
                    App migration involves transferring an existing app from one platform to iOS, or
                    upgrading an existing iOS app to a newer version of iOS. This may require making
                    changes to the app&apos;s design, functionality, and code to ensure it is
                    compatible with the latest iOS versions and devices.
                  </p>
                  <p className="mt-4 mb-10">
                    <b>App Consulting and Strategy: </b>
                    App consulting and strategy services involve providing expert advice and
                    guidance on app development strategies, market research, user experience (UX)
                    design, and app monetization strategies. This helps clients make informed
                    decisions about their app development projects and ensures they are aligned with
                    their business goals.
                  </p>
                  <p className="mt-4 mb-10">
                    Financial software services are typically highly regulated and require robust
                    security measures to protect sensitive financial data. They are used by
                    financial institutions to automate and streamline their financial operations,
                    enhance risk management, ensure compliance with regulatory requirements, and
                    make informed financial decisions.
                  </p>
                </div>
              </div>
              <img src={ios} alt="Ios-image" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ios;
