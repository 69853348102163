import React from "react";
import banner from "../../assets/machine-banner.svg";
import machine from "../../assets/machine-ill.svg";
import machine1 from "../../assets/machine-ill1.svg";

const MachineLearningReadMore = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} />
        <h1 className="absolute text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Machine Learning
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex items-center justify-center pt-10 pb-12">
            <div className="w-full md:w-10/12 content-center">
              <p className="text-center leading-relaxed p-5 whitespace-pre-wrap">
                {" "}
                Machine learning, a cutting-edge field of research, enables computers to learn on
                their own using past data. Machine learning makes predictions based on historical
                data or information using a variety of techniques to build mathematical models. It
                is being used for many various purposes, such as speech recognition, recommender
                systems, email filtering, Facebook auto-tagging, and image identification.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black pb-4">
                    How does Machine Learning work?
                  </p>
                  <p className="">
                    When a machine learning system receives new data, it forecasts the outcome using
                    the prediction models it has built using prior data. The amount of data used
                    determines how well the output is anticipated, as a larger data set makes it
                    easier to create a model that predicts the outcome more precisely.
                  </p>
                  <p className="mt-2">
                    Imagine that we have a complex problem that requires some predictions. Instead
                    of creating code for it, we can simply input the data to generic algorithms, and
                    the machine would develop the logic according to the data and forecast the
                    output. Machine learning has changed our perspective on the problem.
                  </p>
                  <p className="text-2xl font-bold text-black mt-6">Features of Machine Learning</p>
                  <ul className="pl-4">
                    <li className="list-disc mt-2">
                      Data is used by machine learning to find different patterns in a dataset.
                    </li>
                    <li className="list-disc mt-2">
                      Through the use of historical data, it can automatically improve.
                    </li>
                    <li className="list-disc mt-2">
                      Machine Learning is a data-driven technology.
                    </li>
                    <li className="list-disc mt-2">
                      Data mining and machine learning are quite similar because both works with
                      vast amounts of data.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={machine} alt="work-content" className="" />
              </div>
            </div>
          </div>
          <div className="text-start">
            <p className="text-2xl font-bold text-black mt-4">
              Need for Machine Learning technology
            </p>
            <p className="mt-2">
              Machine learning is becoming more and more necessary. Machine learning is necessary
              because it can perform activities that are too complex for a person to carry out
              directly. We need computer systems because it&apos;s difficult for us to access such a
              large amount of data manually. Machine learning can be helpful in this situation.
            </p>
            <p className="mt-2">
              We can teach machine learning algorithms to analyze the data, create models, and
              predict the intended output automatically by providing them with enormous amounts of
              data. The cost function can be used to gauge how well the machine learning algorithm
              performs in relation to the volume of data.
            </p>
            <p className="mt-2">
              By looking at various application cases, one may quickly understand the significance
              of machine learning. Self-driving cars, cyber fraud detection, face recognition,
              Facebook friend recommendations, etc. are currently available. all employ machine
              learning. Leading businesses like Netflix and Amazon have created machine-learning
              algorithms that are used to track customer interest and make product recommendations.
            </p>
          </div>
          <div className="flex justify-center mt-8">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-2xl font-bold text-black">
                    Classification of Machine Learning
                  </p>
                  <p className="mt-2">The three types of Machine Learning are given below : </p>
                  <ul className="pl-4">
                    <li className="list-disc mt-4">Supervised Learning</li>
                    <li className="list-disc mt-4">Unsupervised Learning</li>
                    <li className="list-disc mt-4">Reinforcement Learning </li>
                  </ul>
                  <p className="text-2xl font-bold text-black mt-4">Supervised Learning</p>
                  <p className="mt-2">
                    In supervised learning, the machine learning system receives sample-labeled data
                    as training material and uses that data to predict the outcome.
                  </p>
                  <p className="mt-2">
                    Once the training and processing are complete, we test the model by supplying
                    sample data to see if it is forecasting the exact output or not. To evaluate the
                    datasets and learn about each dataset, the system creates a model using labeled
                    data.
                  </p>
                  <p className="text-2xl font-bold text-black mt-4">Unsupervised Learning</p>
                  <p className="mt-2">
                    Unsupervised learning is a type of learning where a computer picks up
                    information without any supervision.
                  </p>
                  <p className="mt-2">
                    The machine is trained using a set of unlabeled, unclassified, or uncategorized
                    data, and the algorithm is required to respond independently to that data. The
                    goal of unsupervised learning is to restructure the incoming data into new
                    features or a group of objects with connected patterns.
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                <img src={machine1} alt="work-content" className="mb-10" />
              </div>
            </div>
          </div>
          <div className="w-11/12">
            <p className="text-start text-2xl font-bold text-black mt-4">Reinforcement Learning</p>
            <p className="text-start mt-2 mb-10">
              In a reinforcement learning system, a learning agent is rewarded for each successful
              action and penalized for each unsuccessful one. With the help of this feedback, the
              agent automatically learns and performs better. During reinforcement learning, the
              agent investigates and interacts with its surroundings. An agent performs better since
              its objective is to accrue the most reward points.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineLearningReadMore;
