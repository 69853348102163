import React from "react";
import bipinsinha from "../../assets/Bipin-Sir.png";
import TRSIHA from "../../assets/Trisah-mam-HR.png";
import manjeet from "../../assets/manjeet.png";

export const Step = () => {
  return (
    <div className="lg:mb-80 md:mb-80 mt-16 bg-[#53BCAC] lg:h-[500px]">
      <div className="flex justify-center pt-12 mx-auto">
        <div>
          <p className="pb-3 text-[30px] font-normal text-center text-white"> Our Team </p>
          <h1 className="w-5/6 pb-6 mx-auto text-3xl font-bold text-center text-white xl:text-4xl sm:w-4/6">
            Driving Innovations in Product and Services with Cutting Edge Technologies.
          </h1>
        </div>
      </div>
      <div className="w-full px-10 pt-10">
        <div
          role="list"
          aria-label="Behind the scenes People "
          className="flex-wrap lg:flex md:flex sm:flex md:justify-around sm:justify-around lg:justify-around"
        >
          <div
            role="listitem"
            className="relative mt-16 mb-32 xl:w-1/3 sm:w-3/4 md:w-2/5 sm:mb-24 xl:max-w-sm lg:w-64"
          >
            <div className="overflow-hidden bg-white rounded shadow-md">
              <div className="absolute flex justify-center w-full -mt-20">
                <div className="w-32 h-32">
                  <img
                    className="object-cover w-full h-full rounded-full shadow-md bg-[#2A2C2B]"
                    src={bipinsinha}
                  />
                </div>
              </div>
              <div className="px-6 mt-16">
                <h1 className="mb-1 text-3xl font-bold text-center">Bipin Sinha</h1>
                <p className="text-sm text-center text-gray-800">Founder and Director </p>
                <p className="pt-3 pb-4 text-base font-normal text-center text-gray-600">
                  To aid staff in concentrating their efforts, he developed key performance
                  indicators. Work with other company experts to coordinate projects involving
                  community service, promote adherence to corporate laws and regulations and ensure
                  that the company&apos;s code of ethics is being followed.
                </p>
              </div>
            </div>
          </div>
          <div
            role="listitem"
            className="relative mt-16 mb-32 xl:w-1/3 lg:mx-3 sm:w-3/4 md:w-2/5 sm:mb-24 xl:max-w-sm lg:w-64"
          >
            <div className="overflow-hidden bg-white rounded shadow-md">
              <div className="absolute flex justify-center w-full -mt-20">
                <div className="w-32 h-32">
                  <img className="object-cover w-full h-full rounded-full shadow-md" src={TRSIHA} />
                </div>
              </div>
              <div className="px-6 mt-16">
                <h1 className="mb-1 text-3xl font-bold text-center">Trisha Mukherjee</h1>
                <p className="text-sm text-center text-gray-800">HR Manager</p>
                <p className="pt-3 text-base font-normal text-center text-gray-600 pb-4">
                  Her dedication to work and leadership qualities helped the organization in molding
                  into a perfect workplace. She led and enabled the organization into effective
                  digital transforma
                  <br />
                  tion, and improved talent acquisition and psychometric instruments.
                  <br /> <br />
                </p>
              </div>
            </div>
          </div>
          <div
            role="listitem"
            className="relative mt-16 mb-32 xl:w-1/3 sm:w-3/4 md:w-2/5 sm:mb-24 xl:max-w-sm lg:w-64 pb-10"
          >
            <div className="overflow-hidden bg-white rounded shadow-md">
              <div className="absolute flex justify-center w-full -mt-20">
                <div className="w-32 h-32">
                  <img
                    className="object-cover w-full h-full rounded-full shadow-md bg-[#2A2C2B]"
                    src={manjeet}
                  />
                </div>
              </div>
              <div className="px-6 mt-16">
                <h1 className="mb-1 text-3xl font-bold text-center">Manjeet Kumar Shahi</h1>
                <p className="text-sm text-center text-gray-800">Financial Advisor </p>
                <p className="pt-3 text-base font-normal text-center text-gray-600 pb-10">
                  The way he handles the finance and project of the organization is unique. He helps
                  clients in knowing personnel as well as professional and financial information of
                  the client by making use of data to create projections and client goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
