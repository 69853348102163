import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LogoPando from "../../assets/LogoPando.png";
import account from "../../assets/account.png";
import { NavSmallScreen } from "./NavSmallScreen";
import { HashLink } from "react-router-hash-link";
import { Solution } from "../Solution/Solution";
import { useOutsideClick } from "./useOutsideClick";
import { Services } from "../../Services/Services";
import { Products } from "../products/Products";

type ToggleMobileMenu = () => void;

export const Header = () => {
  const ServiceMenuRef = useRef<HTMLDivElement>(null);
  const solutionMenuReff = useRef<HTMLDivElement>(null);
  const productMenuRef = useRef<HTMLDivElement>(null); 
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const [isSolution, setIsSolution] = useState<boolean>(false);
  const [isService, setIsService] = useState<boolean>(false);
    const [isProduct, setIsProduct] = useState<boolean>(false);

  const showSolutions = useCallback(() => {
    setIsSolution(function (prevState) {
      return !prevState;
    });
  }, []);

  const showServices = useCallback(() => {
    setIsService(function (prevState) {
      return !prevState;
    });
  }, []);

  const showProduct = useCallback(() => {
    setIsProduct((prevState) => !prevState); 
  }, []);

  useOutsideClick(solutionMenuReff.current!, () => {
   
    setIsSolution(false);
  });

  useOutsideClick(ServiceMenuRef.current!, () => {
    setIsService(false);
  });


  useOutsideClick(productMenuRef.current!, () => {
    setIsProduct(false);
  }); 
  
  const mobileMenuToggle: ToggleMobileMenu = () => setIsNavOpen((prev) => !prev);

  return (
    <div className="relative z-10 w-full mx-auto bg-white shadow-xl max-w-10xl sm:px-6 md:px-8 md:w-full h-[80px]">
      <div className="fixed top-0 left-0 w-full bg-white">
        <div className="flex items-center justify-between md:justify-start md:w-full">
          <div className="flex px-5 lg:flex-1">
            <Link to={"/"}>
              <span className="sr-only">Company</span>
              <img
                className="w-20 lg:w-full md:w-full sm:w-full my-4 h-[50px]"
                src={LogoPando}
                alt="mainLogo"
              />
            </Link>
            <div className="items-center hidden nav-links md:flex md:flex-1 md:w-full lg:w-0 justify-end ">
              <div className="flex">
                <div className="links" ref={solutionMenuReff}>
                  {" "}
                  <span onClick={showSolutions} className="cursor-pointer">
                    Solutions
                  </span>
                </div>
                {isSolution ? <Solution toggle={mobileMenuToggle} /> : ""}
                <div className="links" ref={ServiceMenuRef}>
                  <span onClick={showServices} className="cursor-pointer">
                    Services
                  </span>
                </div>
                {isService ? <Services toggle={mobileMenuToggle} /> : ""}
                <div className="links" ref={productMenuRef}>
                  <span onClick={showProduct} className="cursor-pointer">
                    Product
                  </span>
                </div>
                {isProduct && <Products toggle={mobileMenuToggle} />}
                <HashLink smooth to="/about" className="links">
                  About Us
                </HashLink>
                <HashLink smooth to="/blog" className="links">
                  Blog
                </HashLink>
                <HashLink smooth to="/Career" className="links">
                  Career
                </HashLink>
                <HashLink smooth to="/contact" className="links">
                  Contact Us
                </HashLink>
              </div>
            </div>
          </div>
          {/* <div className="border justify-center border-gray-400 rounded-lg w-24 lg:w-28 md:w-28 h-10 md:h-12 lg:h-12 lg:mr-5 md:mr-5">
            <div className="w-6">
              <div className="text-center mt-2">
                <img
                  className="absolute ml-1 lg:ml-2 w-6 lg:w-auto md:w-auto sm:w-auto h-6 sm:h-7 text-cyan-900"
                  src={account}
                  alt="mainLogo"
                />
                <HashLink
                  smooth
                  to="/login"
                  className="text-md ml-10 lg:ml-12 md:ml-10 md:text-lg font-semibold whitespace-nowrap text-cyan-900 hover:text-gray-900"
                >
                  Login
                </HashLink>
              </div>
            </div>
          </div> */}
          <section className="flex MOBILE-MENU md:hidden lg:hidden">
            <button className="px-5 space-y-2 HAMBURGER-ICON " onClick={mobileMenuToggle}>
              <span className=""></span>
              <span className=""></span>
              <span className=""></span>
            </button>
            {isNavOpen ? <NavSmallScreen toggle={mobileMenuToggle} /> : null}
          </section>
        </div>
      </div>
    </div>
  );
};
