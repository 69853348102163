import React from "react";
import { HashLink } from "react-router-hash-link";
import iot from "../assets/Services-icon/internet-things.svg";
import bigData from "../assets/Services-icon/big-data.svg";
import machine from "../assets/Services-icon/machine.svg";
import webDesign from "../assets/Services-icon/website-design.svg";
import enterprise from "../assets/Services-icon/enterprise-development.svg";
import uiux from "../assets/Services-icon/ui-ux.svg";
import financial from "../assets/Services-icon/financial-services.svg";
import ios from "../assets/Services-icon/ios.svg";
import customer from "../assets/Services-icon/customer-application.svg";
import android from "../assets/Services-icon/android.svg";

export const Services = ({ toggle }: { toggle: () => void }) => {
  return (
    <div className="absolute left-0 top-[80px] w-full h-auto overflow-x-auto">
      <div className="w-full relative bg-[#FFFFFF] text-center overflow-x-auto">
        <div className="w-10/12 inline-block overflow-x-auto">
          <ul className="list-none flex flex-wrap justify-start pb-10">
            <li className="sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={iot} alt="market-research" className="absolute pt-2" />
              <HashLink to="/internet-things" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Internet of Things (Iot)</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Connecting the world, enabling smart and
                <p className="text-[#565656]">efficient digital ecosystems</p>
              </p>
            </li>
            <li className="sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={bigData} alt="market-research" className="absolute pt-2" />
              <HashLink to="/big-data" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Big Data</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Unlocking valuable insights for informed
                <p className="text-[#565656]">decision-making and growth</p>
              </p>
            </li>
            <li className="sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={machine} alt="market-research" className="absolute pt-2" />
              <HashLink to="/machine-learning" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Machine Learning</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Transforming industries through intelligent
                <p className="text-[#565656]">data-driven algorithms</p>
              </p>
            </li>
            <li className="sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={webDesign} alt="market-research" className="absolute pt-2" />
              <HashLink to="/web-design" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Website Design</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Creating visually captivating and user-friendly
                <p className="text-[#565656]">digital experiences</p>
              </p>
            </li>
            <li className="sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={enterprise} alt="market-research" className="absolute pt-2" />
              <HashLink to="/enterprise-dev" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Enterprise Development</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Empowering businesses through strategic growth
                <p className="text-[#565656]">and technological advancements</p>
              </p>
            </li>
            <li className="sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={uiux} alt="market-research" className="absolute pt-2" />
              <HashLink to="/ui-ux" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">UI/UX Services</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Crafting intuitive and engaging digital experiences
                <p className="text-[#565656]"> for users.</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start w-full my-4">
              <img src={financial} alt="market-research" className="absolute pt-2" />
              <HashLink to="/financial" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Financial Software Services</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Empowering businesses with intelligent financial
                <p className="text-[#565656]">management solutions</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start my-4">
              <img src={ios} alt="market-research" className="absolute pt-2" />
              <HashLink to="/ios-development" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">iOS Development</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Building innovative and seamless experiences
                <p className="text-[#565656]">for Apple users</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start my-4">
              <img src={android} alt="market-research" className="absolute pt-2" />
              <HashLink to="/android" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Android Development</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Creating dynamic and user-friendly apps for
                <p className="text-[#565656]">diverse audiences</p>
              </p>
            </li>
            <li className=" w-full sm:w-1/2 lg:w-1/3 text-start my-4">
              <img src={customer} alt="market-research" className="absolute pt-2" />
              <HashLink to="/customer" onClick={toggle}>
                <p className="text-xl text-[#26C0B6] pl-10">Customer Application</p>
              </HashLink>
              <p className="text-[#565656] pl-10 text-sm pt-1">
                Enhancing engagement and convenience for users
                <p className="text-[#565656]">needs</p>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
