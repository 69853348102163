import React, { useState } from "react";
import axios from "axios";
import LogoPando from "../../assets/LogoPando.png";
import { FaHome } from "react-icons/fa";
import copyright from "../../assets/copyright.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export function Footer() {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState(null);

  const saveEmail = async () => {
    try {
      if (!email) {
        setResponse({
          success: false,
          message: "Email is required",
        });
        return;
      }

      const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        setResponse({
          success: false,
          message: "Please enter a valid email",
        });
        return;
      }

      const { data } = await axios.post("https://apipando.pandoconsultants.com/api/subscribe", {
        email,
      });

      if (!data.success && data.message === "User already exists") {
        setResponse({
          success: false,
          message: "Already exists",
        });
        return;
      }

      setResponse(data);
    } catch (error) {
      setResponse({
        success: false,
        message: error.response?.data?.message || "Something went wrong..",
      });
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="text-center bg-teal-200 text-neutral-600 dark:text-neutral-200 lg:text-left">
        <div className="py-4 mx-6 text-center md:text-left pt-10">
          <div className="grid gap-8 grid-1 md:grid-cols-2 lg:grid-cols-4">
            <div className="">
              <div className="flex items-center justify-center mt-6 md:mt-0 md:w-full">
                <div className="relative w-10/12 h-full max-w-md lg:w-40 aboutImg ">
                  <img src={LogoPando} alt="" className=" md:min-w-full lg" />
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.78201908367!2d77.36752357610891!3d28.606315485269707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce59ed188b333%3A0x13827186206f997e!2sPando%20india%20software%20Consultants!5e0!3m2!1sen!2sin!4v1687154767322!5m2!1sen!2sin"
                  width="200px"
                  height="150px"
                  style={{ borderRadius: "15px", border: "8px solid white" }}
                  loading="lazy"
                ></iframe>
              </div>
              <div className="text-center mt-2">
                <p className="text-black font-semibold">Address:</p>
                <p className="text-[#363636]">A-1, Second floor, Sector 59, Noida UP</p>
              </div>
            </div>
            <div className="">
              <h6 className="flex justify-center mb-4 font-semibold uppercase md:justify-start text-[#000000]">
                Solutions
              </h6>
              <ul>
                <li className="my-1">
                  <HashLink
                    to="/market-research"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    Market Research
                  </HashLink>
                </li>
                <li className="my-1">
                  <HashLink
                    to="/block-chain"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    Block Chain Technology
                  </HashLink>
                </li>
                <li className="my-1">
                  <HashLink
                    to="/software-development"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    Software Development
                  </HashLink>
                </li>
                <li className="my-1">
                  <HashLink
                    to="/online-trading"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    Online Trading
                  </HashLink>
                </li>
                <li className="my-1">
                  <HashLink
                    to="/crm"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    CRM/Dashboard
                  </HashLink>
                </li>
                <li className="my-1">
                  <HashLink
                    to="/develop"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    DevOps
                  </HashLink>
                </li>
                <li className="my-1">
                  <HashLink
                    to="/machine"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    Machine Learning
                  </HashLink>
                </li>
                <li className="my-1">
                  <HashLink
                    to="/cloud"
                    onClick={handleClick}
                    className="text-[100%] text-[#484848] hover:underline"
                  >
                    Cloud Service
                  </HashLink>
                </li>
              </ul>
            </div>
            <div className="">
              <h6 className="flex justify-center mb-4 font-semibold uppercase md:justify-start text-[#000000]">
                Industries
              </h6>
              <ul>
                <li className="my-1">
                  <p className="text-[100%] text-[#484848] hover:underline">Education(EdTech)</p>
                </li>
                <li className="my-1">
                  <p className="text-[100%] text-[#484848] hover:underline">Real Estate</p>
                </li>
                <li className="my-1">
                  <p className="text-[100%] text-[#484848] hover:underline">Market Research</p>
                </li>
                <li className="my-1">
                  <p className="text-[100%] text-[#484848] hover:underline">FMCG</p>
                </li>
              </ul>
            </div>
            <div>
              <h6 className="flex justify-center mb-4 font-semibold uppercase md:justify-start text-[#000000]">
                Contact
              </h6>
              <p className="flex items-center justify-center mb-4 md:justify-start  text-[#484848]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  // fill="currentColor"
                  className="w-5 h-5 mr-3"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                info@pandoconsultants.com
              </p>
              <p className="flex items-center justify-center mb-4 md:justify-start  text-[#484848]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  // fill="currentColor"
                  className="w-5 h-5 mr-3"
                >
                  <path d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" />
                </svg>
                +91-7338593915
              </p>
              {/* <p className="text-black mt-10 text-2xl font-thin tracking-wide leading-tight">
                <strong>Subscribe</strong>
              </p>
              <p className="text-[#484848] leading-5 mt-3">
                Never miss out an update, by subscribing<br></br> to us
              </p>
              <div className="relative mt-4">
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block h-11 w-full p-4 text-sm text-gray-900 border border-gray-500 rounded-full placeholder-gray-500 text-black focus:outline-none focus:border-gray-500"
                  placeholder="Email Address"
                />
                <button
                  type="submit"
                  onClick={saveEmail}
                  className="text-white absolute right-0 bottom-0 h-11 bg-orange-500 font-medium rounded-full text-md px-4 py-2"
                >
                  Subscribe
                </button>
              </div> */}
              {response && (
                <div className="text-start mt-2">
                  {response.success ? (
                    <p className="text-green-600">Subscribe Successfully</p>
                  ) : (
                    <p className="text-red-500">{response.message}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="flex p-6 bg-teal-200 bg-neutral-200 text-[#484848] grid grid-1 md:grid-cols-2">
          <p>
            {" "}
            <img src={copyright} alt="" className="inline-block"></img> 2023 Pando India Software
            Consultants
          </p>
          <div className="flex justify-center ml-auto">
            <p className="mr-5 text-center text-lg">Follow us on:</p>
            <a
              href="https://www.facebook.com/people/Pando-India-Software-Consultants/100089263113195/"
              className="mr-6 ml-auto mt-1 text-neutral-600"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>
            {/* <a
              href="https://www.instagram.com/zampliasurveys/"
              className="mr-6 mt-1 text-neutral-600"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a> */}
            <a
              href="https://www.linkedin.com/company/pando-india-software-consultants/mycompany/"
              target="_blank"
              rel="noreferrer"
              className="mr-6 mt-1 text-neutral-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
            </a>
            {/* <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer"
              className="mr-6 mt-1 text-neutral-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
}
