import { useState } from "react";
import { Model } from "../Model/Model";
import fullStack from "../../assets/fullStack.png";
import projectManager from "../../assets/projectManager.png";
import qualityAssurance from "../../assets/quality.png";
import surveyProgrammer from "../../assets/surveyProgerammer.png";
import dataAnalyst from "../../assets/dataAnalyst.png";
import python from "../../assets/python.png";
import clientService from "../../assets/clientService.png";
import business from "../../assets/Sales.png";
import { HashLink } from "react-router-hash-link";

export const Cardsection = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  if (showPopup) {
    window.scrollTo(200, 10);
  }

  return (
    <div>
      <div
        id="career-card"
        className="flex flex-wrap items-center w-full gap-10 mt-5 justify-evenly md:mt-10"
      >
        <div className="transition-all duration-300 transform bg-[#f7faf9] shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
          <img src={fullStack} alt="" className="rounded-2xl w-full" />
          <div className="text-center">
            <h2 className="flex justify-center text-lg font-bold text-gray-600 ">
              Full Stack Developer
            </h2>
            <p className="text-[11.9px] text-gray-600 p-3">
              {" "}
              As a Full Stack Developer, you should be comfortable with both front-end and back-end
              coding languages, development frameworks, and third-party libraries. You should also
              be a team player with a knack for visual design and utility. To meet the changing
              world of technological challenges, we follow Agile Methodology
            </p>
          </div>
          <div className="flex justify-center m-10">
            <HashLink
              to="/full-stack"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Apply Now
            </HashLink>
          </div>
        </div>{" "}
        <div className="transition-all duration-300 transform bg-[#f7faf9]  shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
          <img src={projectManager} alt="" className="rounded-2xl w-full" />
          <div className="text-center">
            <h2 className="justify-center text-lg font-bold text-gray-600 mt-2">Project Manager</h2>
            <p className="text-[11.9px] text-gray-600 p-3">
              {" "}
              Excellence in managing the team of 50+ members and overseeing end-to-end delivery
              management from conceptualisation and visualisation to technology mapping, budgeting,
              resource & task scheduling and final execution of projects.Interacting with Client &
              Engineering Team for Delivery Management, Scoping and Product Solution.
            </p>
          </div>
          <div className="flex justify-center m-5">
            <HashLink
              to="/project-manager"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Apply Now
            </HashLink>
          </div>
        </div>{" "}
        <div className="transition-all duration-300 transform bg-[#f7faf9] shadow-lg w-[300px] h-[480px] border-[2px] rounded-3xl">
          <img src={qualityAssurance} alt="" className="rounded-2xl h-48 w-full" />
          <div className="text-center">
            <h2 className="flex justify-center text-lg font-bold text-gray-600">
              Quality Assurance
            </h2>
            <p className="text-[11.9px] text-gray-600 p-3">
              {" "}
              A QA Engineer is a professional who finds and fixes bugs in a product or program
              before its launch, collaborating with developers on fixes to those problems when
              necessary. They&lsquo;re needed across many industries, including automotive, medical
              devices and food/beverage.
            </p>
          </div>
          <div className="flex justify-center m-16">
            <HashLink
              to="/quality-assurance"
              onClick={handleClick}
              className="border-2 border-orange-400 hover:bg-[#FFF2E9] w-[110px] rounded-lg font-medium py-1 text-orange-500 text-center"
            >
              Apply Now
            </HashLink>
          </div>
        </div>{" "}
      </div>
      {showPopup && <Model />}
    </div>
  );
};
