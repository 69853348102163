import React from "react";
import banner from "../../assets/Customer-banner.png";
import customer from "../../assets/customer-img.svg";

const Customer = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Customer Application
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-start leading-relaxed whitespace-pre-wrap">
                    Software services in customer applications refer to cloud-based or web-based
                    applications that are designed to provide customer-centric functionalities and
                    capabilities. These services are used by businesses to enhance their customer
                    relationship management (CRM) efforts, improve customer engagement, and provide
                    excellent customer experiences. Customer application software services are used
                    across various industries and sectors, including retail, e-commerce,
                    hospitality, healthcare, telecommunications, and more.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>Customer Relationship Management (CRM) Software: </b>CRM software provides
                    tools and capabilities for businesses to manage their interactions and
                    relationships with customers. It typically includes features for customer data
                    management, contact management, lead and opportunity management, sales
                    automation, customer service, and analytics. CRM software helps businesses
                    streamline their sales, marketing, and customer service processes and allows
                    them to have a unified view of their customers, leading to better customer
                    engagement and retention.
                  </p>
                  <p className="mt-4">
                    <b>Customer Engagement and Experience Software: </b>Customer engagement and
                    experience software focuses on providing tools and capabilities to businesses
                    for delivering exceptional customer experiences across multiple touchpoints,
                    such as websites, mobile apps, social media, and more. It includes features for
                    personalization, content management, customer feedback management, Omni channel
                    engagement, and analytics. This type of software helps businesses create
                    personalized and seamless experiences for their customers, resulting in improved
                    customer satisfaction and loyalty.
                  </p>
                  <p className="mt-4">
                    <b>Customer Support and Helpdesk Software: </b>Customer support and helpdesk
                    software provide functionalities for managing customer inquiries, issues, and
                    support tickets. It typically includes features for ticket management, a
                    knowledge base, self-service portals, live chat, and analytics. This type of
                    software helps businesses streamline their customer support processes, provide
                    timely and effective support to customers, and track and analyze customer
                    interactions for continuous improvement.
                  </p>
                  <p className="mt-4">
                    <b>Feedback and Survey Software: </b>
                    Feedback and survey software allows businesses to collect and analyze customer
                    feedback and insights. It includes features for survey creation, feedback
                    collection, data analysis, and reporting. This type of software helps businesses
                    gather valuable feedback from customers, understand their needs and preferences,
                    and make data-driven decisions to improve their products, services, and overall
                    customer experience.
                  </p>
                  <p className="mt-4">
                    <b>E-commerce and Online Shopping Software: </b>
                    E-commerce and online shopping software provides functionalities for businesses
                    to set up and manage online stores, process online orders, and manage customer
                    interactions. It includes features for product catalog management, shopping cart
                    functionality, payment processing, and order management. This type of software
                    helps businesses create and manage online shopping experiences, streamline
                    online transactions, and provide convenient and secure online shopping
                    experiences for customers.
                  </p>
                  <p className="mt-4 mb-10">
                    <b>Social Media Management Software: </b>
                    Social media management software enables businesses to manage their social media
                    presence, engage with customers on social media platforms, and analyze social
                    media performance. It includes features for social media scheduling, content
                    creation and publishing, social listening, and analytics. This type of software
                    helps businesses effectively manage their social media interactions with
                    customers, monitor social media conversations, and analyze social media
                    performance to optimize their social media strategies.
                  </p>
                </div>
              </div>
              <img src={customer} alt="Customer-image" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
