import React from "react";
import banner from "../../assets/machine-baneer.png";
import learning from "../../assets/learning.svg";

const MachineLearning = () => {
  return (
    <div>
      <div className="w-full flex items-center justify-center">
        <img className="h-auto w-full" src={banner} alt="banner" />
        <h1 className="absolute text-center text-white lg:text-6xl md:text-4xl text-md pl-2 lg:pl-12 md:pl-12 font-extrabold">
          Machine Learning
        </h1>
      </div>
      <div className="w-full text-center">
        <div className="w-11/12 inline-block">
          <div className="flex justify-center mt-20">
            <div className="flex flex-wrap lg:h-auto md:h-auto sm:w-full lg:w-full">
              <div className="md:w-1/2">
                <div className="lg:w-full md:w-11/12 w-full max-h-fit text-start">
                  <p className="text-start leading-relaxed whitespace-pre-wrap">
                    A software service in machine learning refers to a cloud-based or web-based
                    application that provides functionalities and capabilities related to machine
                    learning. These services are designed to enable developers and data scientists
                    to build, deploy, and manage machine learning models without having to develop
                    and maintain their own infrastructure.
                  </p>
                  <p className="text-2xl font-bold text-black pb-4 mt-6">
                    Here are some services we provide:
                  </p>
                  <p className="mt-4">
                    <b>Model Training Services: </b>These services provide tools and APIs for
                    training machine learning models using large datasets. They may offer pre-built
                    machine learning algorithms and frameworks, as well as tools for data
                    preprocessing, feature engineering, and model evaluation.
                  </p>
                  <p className="mt-4">
                    <b>Model Deployment Services: </b>These services allow users to deploy trained
                    machine learning models to production environments, making them accessible for
                    real-time inference or batch processing. They may provide APIs or SDKs for
                    integrating machine learning models into applications, websites, or other
                    services.
                  </p>
                  <p className="mt-4">
                    <b>AutoML (Automated Machine Learning) Services: </b>These services leverage
                    automated techniques to simplify and accelerate the process of building machine
                    learning models. They may offer features such as automated data preprocessing,
                    algorithm selection, hyperparameter tuning, and model evaluation, making machine
                    learning more accessible to users with limited expertise in data science.
                  </p>
                  <p className="mt-4">
                    <b>Model Monitoring and Management Services: </b>These services provide tools
                    for monitoring the performance and health of deployed machine learning models,
                    such as tracking model accuracy, detecting model drift, and managing model
                    versioning. They may also provide features for model retraining, model serving,
                    and model lifecycle management.
                  </p>
                  <p className="mt-4">
                    <b>Data Annotation and Labeling Services: </b>
                    These services assist with the process of annotating and labeling large datasets
                    for training machine learning models. They may provide tools for image labeling,
                    text annotation, or other types of data labeling tasks, which are essential for
                    supervised machine learning.
                  </p>
                  <p className="mt-6 mb-10">
                    Examples of popular software services in machine learning include Amazon
                    SageMaker, Google Cloud Machine Learning Engine, Microsoft Azure Machine
                    Learning, IBM Watson Studio, and TensorFlow Extended (TFX). These services
                    provide a range of capabilities for developing, deploying, and managing
                    machine-learning models, and are widely used by organizations and data
                    scientists to accelerate their machine-learning workflows.
                  </p>
                </div>
              </div>
              <img src={learning} alt="machine-img" className="lg:w-5/12 md:w-1/2 mt-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineLearning;
